@use "sass:map";

$white: #ffffff;
$primary: #004355;

// https://huemint.com/bootstrap-plus/
$theme-colors: (
    "light":      #c7d9e7,
    "dark":       #050509,
    "primary":    $primary,
    "secondary":  #327e81,
    "info":       #2990a4,
    "accent1":    #344a51,
    "accent2":    #ff8d22,
    "accent3":    #9aaeaf,
    "success":    #00a956,
    "warning":    #e9ca00,
    "danger":     #f7001c,
);

// $body-bg: seagreen;
//$body-bg: white;
$body-bg: map.get($theme-colors, "secondary");
$body-color: black;
$tooltip-bg: map.get($theme-colors, "accent2");
$tooltip-color: black;

$modal-content-color: black;
$modal-content-bg: white;

// $input-group-addon-bg: $primary;

#inputs-list {
    height: 80vh;
}

#inputs-accordion {
    height: 100%;
    overflow: auto;
}

.input.accordion-item {
    border-top: solid 2px map.get($theme-colors, "accent2") !important;
}

.input .accordion-button {
    padding: 0.5em;
    padding-top: 0.1em;
    padding-bottom: 0.25em;
    color: map.get($theme-colors, "primary");
    background-color: map.get($theme-colors, "light");
}

.input .accordion-button:focus {
    box-shadow: none;
}

.input-text {
    background-color: darkgray;
}

.input-rectangle {
    background-color: red;
}

.input-preview-audio-track {
    color: map.get($theme-colors, "primary");
    background-color: map.get($theme-colors, "light");
    border: solid map.get($theme-colors, "primary") 1px;
}

.react-draggable {
    transform: none !important;
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.scss';

.header {
    color: white;
    background-color: map.get($theme-colors, "primary");;
    text-align: center;
} 

.modal-header {
    background-color: $primary;
    color: white;
    .btn-close {
        background-color: white
    }
}

.form-select {
    color: white;
}

.form-control {
    color: white;
}

.form-control:focus {
    color: white;
}

.react-colorful {
    width: 100% !important;
}

#inputs-accordion .accordion-body {
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cce0cc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary;
}

/* hide Input number arrows*/
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/** Lottie player ***/
.lf-player-container {
    width: 100%;
    height: 100%;
}

/** Transition to hide controls on players **/
.fade-out {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1.5s, opacity 1.5s;
}